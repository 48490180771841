// Spacing helpers, which are available in Bootstrap 4, but not in 3
.m-0 {
    margin:0!important;
}
.mt-0 {
    margin-top:0!important;
}
.mr-0 {
    margin-right:0!important;
}
.mb-0 {
    margin-bottom:0!important;
}
.ml-0 {
    margin-left:0!important;
}
.mx-0 {
    margin-left:0!important;
    margin-right:0!important;
}
.my-0 {
    margin-top:0!important;
    margin-bottom:0!important;
}

.p-0 {
    padding:0!important;
}
.pt-0 {
    padding-top:0!important;
}
.pr-0 {
    padding-right:0!important;
}
.pb-0 {
    padding-bottom:0!important;
}
.pl-0 {
    padding-left:0!important;
}
.px-0 {
    padding-left:0!important;
    padding-right:0!important;
}
.py-0 {
    padding-top:0!important;
    padding-bottom:0!important;
}
