.break-word {
    overflow-wrap: break-word;
}

.clear-inner-margin {
    & > *:first-child {
        margin-top: 0 !important;
    }

    & > *:last-child {
        margin-bottom: 0 !important;
    }
}

.w-full {
    width: 100%;
}

.d-flex {
    display: flex;
}

.items-center {
    align-items: center;
}

.justify-center {
    justify-content: center;
}

.justify-between {
    justify-content: space-between;
}

.flex-grow {
    flex-grow: 1;
}

.g-3 {
    gap: 1.5rem;
}

.m-0 {
    margin: 0;
}

.mt-3 {
    margin-top: 1.5rem;
}

.mt-6 {
    margin-top: 3rem;
}

.mb-1 {
  margin-bottom: .5rem;
}

.mb-2 {
  margin-bottom: 1rem;
}

.mb-3 {
    margin-bottom: 1.5rem;
}

.mb-6 {
    margin-bottom: 3rem;
}

.p-0 {
    padding: 0;
}

.relative {
    position: relative !important;
}
.absolute {
    position: absolute !important;
}
