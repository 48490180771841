.dropdown-menu {
  padding: 0;
  > li {
    > a {
      padding: 1.5rem 2.25rem;
      border-bottom: 1px solid $dropdown-border;
    }

    &.active,
    &.active:hover,
    &.active:focus {
      > a {
        color: #fff;
        background-color: $brand-primary;
        border-color: $brand-primary;
      }
    }
  }

}
