.message-form {
  padding: 15px 15px 0 15px;
  margin: 0 0 20px 0;
  background: $gray-lighter;
  display: flex;
  flex-direction: column;

  &:after {
    content: '';
    border-width: 15px 15px 0;
    border-style: solid;
    position: relative;
    top: 15px;
    border-color: $gray-lighter transparent;
    align-self: center;
    width: 0;
  }
}

.chat-list {
  @include clearfix();
  background: $body-bg;
  padding: 15px;

  .chat-item {
    margin-bottom: 10px;
    padding: 10px;
    position: relative;
    float: left;
    clear: both;

    @include isaac-respond-min-width(sm) {
      max-width: calc(100% - 140px);
    }

    //icon
    &.chat-item-atradius,
    &.chat-item-customer {
      border-radius: 4px;
      background: #fff;
      &:before {
        content: $icons-var-atradius;
        @include icon();
        display: block;
        position: absolute;
        left: -50px;
        top: 7px;
        width: 36px;
        height: 36px;
        text-align: center;
        border: 1px solid $link-color;
        background: #fff;
        border-radius: 50%;
        font-size: 28px;
        line-height: 34px;
        color: $link-color;
        overflow: hidden;
      }
    }

    .chat-date {
      display: block;
    }

    .chat-message {
      overflow-wrap: break-word;
      .expand-link {
        text-align: center;
      }
    }

    &.chat-item-atradius {
      margin-left: 50px;
      border: 1px solid $gray-lighter;

      //icon
      &:before {
        color: #dc0028;
      }

      //speech bubble arrow border
      .chat-item-body:before {
        content: ' ';
        position: absolute;
        display: block;
        width: 0;
        height: 0;
        left: -16px;
        top: 16px;
        border: 8px solid;
        border-color: transparent $gray-lighter transparent transparent;
      }

      //speech bubble arrow fill
      .chat-item-body:after {
        content: ' ';
        position: absolute;
        display: block;
        width: 0;
        height: 0;
        left: -18px;
        top: 12px;
        border: 12px solid;
        border-color: transparent #fff transparent transparent;
      }
    }

    &.chat-item-customer {
      margin-right: 50px;
      float: right;
      background-color: $gray-lighter;

      //speech bubble
      .chat-item-body:after {
        content: ' ';
        position: absolute;
        display: block;
        width: 0;
        height: 0;
        top: 16px;
        right: -16px;
        border: 8px solid;
        border-color: transparent transparent transparent $gray-lighter;
      }

      //icon
      &:before {
        content: $icons-var-user;
        left: auto;
        right: -50px;
        font-size: 35px;
      }
    }
  }

  .chat-item-attradius + .chat-item-customer {
    margin-bottom: 120px;
  }
}
