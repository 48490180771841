.elq-form p input[type='text'],
.elq-form p select {
    display: block;
    max-width: 95% !important;
    height: 32px;
    padding: 2px 12px;
    font-size: 16px;
    line-height: 1.5;
    color: #333;
    background-color: #fff;
    background-image: none;
    border: 1px solid #e6e6e6;
    border-radius: 0;
}

.elq-form p select {
    max-width: 98%;
}

.elq-form p textarea {
    display: block;
    max-width: 95% !important;
    padding: 6px 12px;
    font-size: 16px;
    line-height: 1.5;
    height: 100px;
    color: #333;
    background-color: #fff;
    background-image: none;
    border: 1px solid #e6e6e6;
    border-radius: 0;
}

.elq-form p input[type='submit'] {
    display: inline-block;
    margin-bottom: 0;
    font-weight: bold;
    text-align: center;
    height: 38px !important;
    white-space: nowrap;
    vertical-align: middle;
    cursor: pointer;
    background-image: none;
    border: 1px solid transparent;
    padding: 4px 12px;
    font-size: 16px;
    line-height: 1.5;
    border-radius: 0;
    color: #fff;
    background-color: #dc0028 !important;
    border-color: #dc0028;
}

.elq-form label.instructions {
    font-size: 13px !important;
}

.elq-form .checkbox-label {
    bottom: 0 !important;
}
