.btn {
  text-transform: uppercase;
  font-size:14px;
  padding: 5px 26px 4px;
  transition: background 0.2s, border-color 0.2s;
  font-family: $btn-font-family;

  &:focus,
  &.focus,
  &:hover,
  &:active,
  &.active {
    box-shadow: none;
  }
}

.btn-wide {
    padding: 5px 52px 4px;
}

.btn-lg {
  font-size:16px;
  padding: 9px 26px 8px;
}

.btn-secondary {
  @include button-variant($btn-secondary-color, $btn-secondary-bg, $btn-secondary-border);
}

.btn-primary,
.btn-secondary,
.btn-default {
  &.disabled:not.btn-loading,
  &[disabled]:not.btn-loading,
  fieldset[disabled] &:not.btn-loading {
    &,
    &:hover,
    &:focus,
    &.focus {
      opacity:1;
      background-color: transparent;
      border-color: $btn-bg-disabled;
      color: $btn-color-disabled;
    }
  }
}

.btn-loading {
  &,
  &[disabled],
  &:hover,
  &:focus  {
    position:relative;
    cursor: wait;
    &:after {
      opacity:1;
      position:absolute;
      bottom:-1px;
      left:0;
      width:0;
      height:2px;
      background:rgba(0,0,0,0.3);
      display:block;
      content:'';
      animation: btn-loading 1.3s infinite;
    }
  }
  &.btn-default {
    &:after {
      background: rgba(0,0,0,0.5);
    }
  }
  &.btn-primary {
    &:after {
      background: rgba(255,255,255,0.6);
    }
  }
}


.btn-link {
  font-weight: bold;
  padding-left:0;
  padding-right:0;

  &.disabled,
  &[disabled],
  fieldset[disabled] & {
    &,
    &:hover,
    &:focus,
    &.focus {
      opacity:1;
      color: $btn-color-disabled;
    }
  }
}

.btn-primary {
  &:focus,
  &.focus,
  &:hover,
  &:active,
  &.active {
    background-color: $btn-primary-bg-hover;
    border-color: $btn-primary-bg-hover;
  }

}

.btn-default {
  &:focus,
  &.focus,
  &:hover,
  &:active,
  &.active {
    background-color: $btn-default-bg-hover;
    border-color: $btn-default-bg-hover;
  }
}

.btn-secondary {
  &:focus,
  &.focus,
  &:hover,
  &:active,
  &.active {
    border-color: $gray-dark;
    color: $gray-dark;
  }
}

.btn-arrow-left {
  @include isaac-respond-min-width(md) {
    position:relative;
    &:before {
      content:'';
      display: block;
      position: absolute;
      background: url(img/arrow.svg) no-repeat;
      height:60px;
      width:140px;
      left:-170px;
      top:-32px;
    }

    &.btn-lg {
      &:before {
        top:-28px;
      }
    }
  }
}

@keyframes btn-loading {
  0% {
    width:0;
    left:0;
  }
  20% {
    width:60%;
    left:0;
  }
  50% {
    width:100%;
    left:0;
  }
  51% {
    width:100%;
    left:auto;
    right:0;
  }
  100% {
    width:0;
    left:auto;
    right:0;
  }
}
