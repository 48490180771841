.validation-pending {
  position:absolute;
  display:inline-block;
  $base-line-height: 24px;
  $spin-duration: 1.6s;
  top:4px;
  right:$base-line-height;

  @include isaac-respond-min-width(sm) {
    right:-$base-line-height;
  }

  @keyframes validation-pending-spin {
    0% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(360deg);
    }
  }

  border-radius: 50%;
  width: $base-line-height;
  height: $base-line-height;
  border: .25rem solid rgba(0,0,0,0.2);
  border-top-color: $gray;
  animation: validation-pending-spin $spin-duration infinite linear;
}
