.wizard-step-enter-active {
  animation: wizard-step-in 0.3s;
}

.wizard-step-leave-active {
  animation: wizard-step-out 0.3s;
}


@keyframes wizard-step-in {
  0% {
    opacity:0;

  }
  100% {
    opacity:1;

  }
}

@keyframes wizard-step-out {
  0% {
    opacity:1;

  }
  100% {
    opacity:0;

  }
}