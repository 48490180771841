.panel-wizard {
    position: relative;
    @include panel-variant(
        $panel-default-border,
        $panel-default-text,
        $panel-default-heading-bg,
        $panel-default-border
    );

    &.panel-wizard-loading {
        cursor: wait;
    }

    .wizard-step-container {
        position: relative;

        > .panel-body {
            padding-top: 30px;
        }
    }

    .nav-wizard {
        position: absolute;
        top: -15px;
        left: 0;
        width: 100%;
        text-align: center;

        > ul {
            margin: 0;
            padding: 0;
            display: inline-block;
            list-style: none;
            @include clearfix();

            > li {
                position: relative;
                display: block;
                float: left;
                z-index: 1;

                > a {
                    display: block;
                    cursor: default;
                    padding: 0;
                    width: 28px;
                    height: 28px;
                    line-height: 28px;
                    border-radius: 50%;
                    text-align: center;
                    background-color: #fff;
                    border: 1px solid $gray;
                    color: $gray;
                    font-size: 15px;
                    font-family: $font-family-serif-medium;
                    font-weight: normal;
                    transition: background-color 0.6s, border 0.6s;
                    z-index: 1;

                    &:hover,
                    &:focus {
                        text-decoration: none;
                    }

                    //divider
                    &:before {
                        content: '';
                        display: block;
                        position: absolute;
                        width: 16px;
                        height: 1px;
                        background: $gray;
                        top: 14px;
                        left: 27px;
                    }
                }

                &:last-child > a {
                    &:before {
                        //no divider on last li
                        display: none;
                    }
                }

                + li {
                    margin-left: 15px;
                }

                // Active state
                &.active > a {
                    &,
                    &:hover,
                    &:focus {
                        background-color: $gray;
                        border-color: $gray;
                        color: #fff;
                    }
                }

                // Completed state
                &.completed > a {
                    color: #fff;
                    //background-color: $brand-success;
                    border-color: $brand-success;
                    text-indent: -999999px;
                    position: relative;
                    &:after {
                        @include icon();
                        content: $icons-var-check;
                        display: block;
                        position: absolute;
                        top: 4px;
                        left: 0;
                        width: 26px;
                        height: 26px;
                        text-indent: 0;
                        color: $brand-success;
                        text-align: center;
                        font-size: 18px;
                    }
                }
            }
        }
    }

    .wizard-back {
        text-align: center;
        padding-top: 15px;
        @include isaac-respond-min-width(sm) {
            text-align: right;
            padding-top: 0;
        }
    }
}

/**
 * Guided Document Wizard (vertical)
 */
.guided-document-wizard {
    position: relative;
    @include panel-variant(
        $panel-default-border,
        $panel-default-text,
        $panel-default-heading-bg,
        $panel-default-border
    );

    &.guided-document-wizard-loading {
        cursor: wait;
    }

    .wizard-step-container {
        position: relative;

        > .panel-body {
            padding-top: 30px;
        }
    }

    .wizard-back {
        text-align: center;
        padding-top: 15px;

        @include isaac-respond-min-width(sm) {
            text-align: right;
            padding-top: 0;
        }
    }
}

//
// Step
.guided-document-wizard-step {
    border-top: 1px solid $panel-default-border;
    padding: $grid-gutter-width 0;

    &.guided-document-wizard-step-disabled {
        position: relative;
        pointer-events: none;

        &:before {
            position: absolute;
            top: 0;
            left: 0;
            right: 0;
            bottom: 0;
            background-color: #fff;
            opacity: 0.75;
            display: block;
            content: '';
            z-index: 1;
        }
    }
}
.guided-document-wizard-step-header {
}
.guided-document-wizard-step-title {
    margin: 0;
}

.guided-document-wizard-step-description {
    color: $btn-color-disabled;
    font-style: italic;
}

.guided-document-wizard-step-footer {
    margin-top: ($grid-gutter-width * 0.5);
}

.guided-document-wizard-step-skip {
    text-align: center;
}

//
// Dropzone
.guided-document-dropzone {
    margin-top: ($grid-gutter-width * 0.5);
}

.guided-document-dropzone-minimal {
    margin-top: ($grid-gutter-width * 0.5);
}
