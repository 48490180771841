.modal-header,
.modal-footer {
    &:empty {
        display: none;
    }
}

.modal[spacious] {
    .modal-body,
    .modal-footer,
    .modal-header {
        padding: 3rem;

        & + .modal-body,
        & + .modal-footer,
        & + .modal-header {
            padding-top: 0;
        }
    }
}
