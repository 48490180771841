.event-list {
    position: relative;

    .event-item {
        position: relative;
        min-width: 100%;
        max-width: calc(100% - 140px);
        background: $body-bg;
        margin-top: 20px;
        padding: 5px;

        // Ruler left hand side
        &:before {
            content: '';
            position: absolute;
            width: 1px;
            background-color: $gray-lighter;
            height: calc(100% + 20px);
            top: 0;
            left: 33px;

            @include isaac-respond-min-width(sm) {
                left: 50px;
            }
        }

        &:first-child:before {
            top: 20px;
        }

        &:last-child:before {
            height: 20px;
        }

        .expand-link {
            text-align: start;
            margin-top: 5px;
        }

        .event-title {
            margin: 0;
            margin-top: 10px;
        }

        .event-subject,
        .event-amount {
            display: block;
        }

        .event-item-content {
            margin: 10px 0 0 0;
        }

        .event-date {
            display: block;
            line-height: 1;
        }

        .event-item-body {
            margin-left: 44px;
            padding: 15px 28px 15px 20px;

            @include isaac-respond-min-width(sm) {
                margin-left: 70px;
            }

            // Icon
            &:before {
                @include icon();
                display: block;
                position: absolute;
                text-align: center;
                border: 1px solid $gray-light;
                background: #fff;
                border-radius: 50%;
                color: #000000;
                overflow: hidden;
                z-index: 1;

                width: 44px;
                height: 44px;
                left: 11px;
                line-height: 40px;
                font-size: 24px;

                @include isaac-respond-min-width(sm) {
                    width: 55px;
                    height: 55px;
                    left: 22px;
                    line-height: 50px;
                    font-size: 32px;
                }
            }

            &.event-item-body-call:before {
                content: $icons-var-call;
            }

            &.event-item-body-document:before {
                content: $icons-var-document;
            }

            &.event-item-body-email:before {
                content: $icons-var-email;
            }

            &.event-item-body-payment-received:before {
                content: $icons-var-payment-received;
            }

            &.event-item-body-invoice:before {
                content: $icons-var-invoice;
            }

            &.event-item-body-summary:before {
                content: $icons-var-summary;
            }

            &.event-item-body-voice-message:before {
                content: $icons-var-voice-message;
            }

            &.event-item-body-video:before {
                content: $icons-var-video;
            }

            &.event-item-body-bell:before {
                content: $icons-var-bell;
            }
        }
    }

    .event-item:first-child {
        margin-top: 0;
    }

    .event-actions {
        display: flex;
        gap: $grid-gutter-width;
        margin-top: 5px;
    }

    .event-attachment {
        text-transform: capitalize;
    }
}
