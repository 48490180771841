.dropzone {
    &:not(.dz-type-button) {
        border: 2px dashed $gray-light;
        text-align: center;
        padding: 15px;
        border-radius: 10px;
        transition: border 400ms;

        &.dz-drag-hover {
            background: $gray-lighter;
        }
        &.has-error {
            border-color: $brand-danger;
        }
    }
}

.dz-button {
    background-color: transparent;
    border: 0;

    .dz-type-button & {
        padding: 0;
        text-transform: uppercase;
    }
}
