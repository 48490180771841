/*
  DO NOT edit this file manually
  This scss file is generated by using gulp icon-font.
  Edit the source in /frontend/icons/_icons.scss
*/

//Mixins
@mixin icon() {
  display: inline-block;
  font: normal normal normal 14px/1 "icons";
  font-size: inherit;
  text-rendering: auto;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

@font-face {
  font-family: "icons";
  src: url('/global/fonts/icons/icons.eot?v=1741865310163');
  src: url('/global/fonts/icons/icons.eot?v=1741865310163#iefix') format('eot'),
  url('/global/fonts/icons/icons.woff2?v=1741865310163') format('woff2'),
  url('/global/fonts/icons/icons.woff?v=1741865310163') format('woff'),
  url('/global/fonts/icons/icons.ttf?v=1741865310163') format('truetype'),
  url('/global/fonts/icons/icons.svg#icons?v=1741865310163') format('svg');
  font-weight: normal;
  font-style: normal;
}

.icon {
  @include icon();
}

.icon-lg {
  font-size: 1.3333333333333333em;
  line-height: 0.75em;
  vertical-align: -15%;
}
.icon-2x { font-size: 2em; }
.icon-3x { font-size: 3em; }
.icon-4x { font-size: 4em; }
.icon-5x { font-size: 5em; }
.icon-fw {
  width: 1.2857142857142858em;
  text-align: center;
}


//Always us e these variables, since the unicode char is not garanteed to be the same every time a new font is generated
$icons-var-alert-circle-outline: "\EA01";
$icons-var-alert-circle: "\EA02";
$icons-var-alert-triangle-outline: "\EA03";
$icons-var-alert-triangle: "\EA04";
$icons-var-atradius: "\EA05";
$icons-var-bell: "\EA06";
$icons-var-calendar: "\EA07";
$icons-var-call: "\EA08";
$icons-var-case-multiple: "\EA09";
$icons-var-case-single: "\EA0A";
$icons-var-check-circle-outline: "\EA0B";
$icons-var-check-circle: "\EA0C";
$icons-var-check: "\EA0D";
$icons-var-chevron-down: "\EA0E";
$icons-var-chevron-left: "\EA0F";
$icons-var-chevron-right: "\EA10";
$icons-var-chevron-up: "\EA11";
$icons-var-close: "\EA12";
$icons-var-cloud-upload: "\EA13";
$icons-var-delete-circle-outline: "\EA14";
$icons-var-delete: "\EA15";
$icons-var-document: "\EA16";
$icons-var-edit-regular: "\EA17";
$icons-var-email: "\EA18";
$icons-var-file: "\EA19";
$icons-var-globe: "\EA1A";
$icons-var-information-cirlcle-outline: "\EA1B";
$icons-var-information: "\EA1C";
$icons-var-invoice: "\EA1D";
$icons-var-paperclip: "\EA1E";
$icons-var-payment-received: "\EA1F";
$icons-var-search: "\EA20";
$icons-var-star-outline: "\EA21";
$icons-var-star: "\EA22";
$icons-var-summary: "\EA23";
$icons-var-user-outline: "\EA24";
$icons-var-user: "\EA25";
$icons-var-video: "\EA26";
$icons-var-voice-message: "\EA27";


.icon-alert-circle-outline:before { content: $icons-var-alert-circle-outline }
.icon-alert-circle:before { content: $icons-var-alert-circle }
.icon-alert-triangle-outline:before { content: $icons-var-alert-triangle-outline }
.icon-alert-triangle:before { content: $icons-var-alert-triangle }
.icon-atradius:before { content: $icons-var-atradius }
.icon-bell:before { content: $icons-var-bell }
.icon-calendar:before { content: $icons-var-calendar }
.icon-call:before { content: $icons-var-call }
.icon-case-multiple:before { content: $icons-var-case-multiple }
.icon-case-single:before { content: $icons-var-case-single }
.icon-check-circle-outline:before { content: $icons-var-check-circle-outline }
.icon-check-circle:before { content: $icons-var-check-circle }
.icon-check:before { content: $icons-var-check }
.icon-chevron-down:before { content: $icons-var-chevron-down }
.icon-chevron-left:before { content: $icons-var-chevron-left }
.icon-chevron-right:before { content: $icons-var-chevron-right }
.icon-chevron-up:before { content: $icons-var-chevron-up }
.icon-close:before { content: $icons-var-close }
.icon-cloud-upload:before { content: $icons-var-cloud-upload }
.icon-delete-circle-outline:before { content: $icons-var-delete-circle-outline }
.icon-delete:before { content: $icons-var-delete }
.icon-document:before { content: $icons-var-document }
.icon-edit-regular:before { content: $icons-var-edit-regular }
.icon-email:before { content: $icons-var-email }
.icon-file:before { content: $icons-var-file }
.icon-globe:before { content: $icons-var-globe }
.icon-information-cirlcle-outline:before { content: $icons-var-information-cirlcle-outline }
.icon-information:before { content: $icons-var-information }
.icon-invoice:before { content: $icons-var-invoice }
.icon-paperclip:before { content: $icons-var-paperclip }
.icon-payment-received:before { content: $icons-var-payment-received }
.icon-search:before { content: $icons-var-search }
.icon-star-outline:before { content: $icons-var-star-outline }
.icon-star:before { content: $icons-var-star }
.icon-summary:before { content: $icons-var-summary }
.icon-user-outline:before { content: $icons-var-user-outline }
.icon-user:before { content: $icons-var-user }
.icon-video:before { content: $icons-var-video }
.icon-voice-message:before { content: $icons-var-voice-message }
