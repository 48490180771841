//default bootstrap Jumbotron css is not used, but the DOM is.
.jumbotron {
  position:relative;
  margin-bottom:20px;
  padding:65px 0;
  background-position: center center;
  background-size: cover;

  .jumbotron-body {
    color: #fff;
    text-shadow: 0px 0px 5px rgba(0,0,0,0.5);

    p {
      font-family: $font-family-serif;
      font-weight: bold;
      font-size: 24px;
    }

    h1 {
      font-size:36px;
      color: #fff;
      margin-top:0;
    }

  }

  .jumbotron-footer {
    color: #fff;
    text-shadow: 0px 0px 5px rgba(0,0,0,0.5);
    font-size:18px;
    padding-top:12px;
    a.btn {
      text-shadow: none;
    }

    a:not(.btn) {
      color: #fff;
      text-decoration: underline;
      font-weight: normal;
    }

  }
}

