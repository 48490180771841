// website without offcanvas menu is contained within #content
#content {
    background-color: $content-bg;
}

.row-padding-sm {
    padding-top: 10px;
    padding-bottom: 10px;
}

.row-padding-md {
    padding-top: 20px;
    padding-bottom: 20px;
}

.row-padding-lg {
    padding-top: 30px;
    padding-bottom: 50px;
}

/*
Equal height columns hack
Warning: Changes column wrapping behavior
If you have put more than 12 columns in one .row-eq-height, the columns will be forced to shrink into a single row, instead of wrapping onto a new line as they normally would.
*/
.row-md-eq-height {
    @include isaac-respond-min-width(md) {
        display: flex;

        div[class^='col-md'],
        div[class*=' col-md-'] {
            display: flex;
            .panel {
                width: 100%;
            }
        }
    }
}

.row-sm-eq-height {
    @include isaac-respond-min-width(sm) {
        display: flex;

        div[class^='col-sm'],
        div[class*=' col-sm-'] {
            display: flex;
            .panel {
                width: 100%;
            }
        }
    }
}

.extra-margin-top {
    margin-top: 10px;
}
