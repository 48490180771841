.collapse-icon {
  //add plus sign
  &:before {
    content: '-';
    display: inline-block;
    width:9px;
  }

  //expanded state
  &:not(.collapsed) {
    .collapse-expanded-hidden {
      display: none;
    }
  }

  &.collapsed {
    &:before {
      content: '+';
    }

    //collapsed state
    .collapse-collapsed-hidden {
      display: none;
    }
  }


}

