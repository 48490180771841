.summary-case-overview {
  width:100%;

  .summary-header {
    display: flex;
    align-items: center;
  }

  .summary-heading {
    margin:0;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
    flex-grow: 1;
  }

  .summary-line {
    margin-bottom: 10px;
    @include clearfix();
    .summary-label {
      float: left;
      margin:0;
    }
    .summary-value {
      float: right;
      text-align: right;
      margin:0;
    }
    &.summary-hero {
      background: $table-bg-accent;
      padding-top:10px;
      padding-bottom:10px;
      margin-bottom: 15px;
    }
  }

  .summary-status {
    min-height: 95px;
    margin-bottom: 10px;
  }

  .summary-footer {
    border-top: 1px dashed $gray-lighter;
    padding: 15px 20px;
    margin: 15px -20px -20px -20px;
  }
}

.summary-case-detail {
  width:100%;

  .summary-line {
    padding:0 10px;
    margin-bottom: 10px;
    @include clearfix();
    .summary-label {
      float: left;
      margin:0;
    }
    .summary-value {
      float: right;
      text-align: right;
      margin:0;
    }
    .summary-info {
      clear: both;
    }

    &.summary-hero {
      background: $table-bg-accent;
      padding-top:10px;
      padding-bottom:10px;
      margin-bottom: 15px;
    }
  }

}
