.alert {
  a {
    font-weight: normal;
    color:inherit;
    text-decoration: underline;
  }
}

.alert-danger {
  a:focus, a:hover {
    color: darken($alert-danger-text,14%);
  }
}

.alert-danger-subtle {
  background-color: $alert-danger-bg;
  border-color: transparent;
  color: $text-color;
}

.alert-success {
  a:focus, a:hover {
    color: darken($alert-success-text,20%);
  }
}

.alert-warning {
  a:focus, a:hover {
    color: darken($alert-warning-text,14%);
  }
}

.alert-info {
  a:focus, a:hover {
    color: darken($alert-info-text,14%);
  }
}

.alert-lg {
  padding: $alert-lg-padding;
}
