.nav-main > ul {
    list-style: none;
    margin: 0;
    @include clearfix;
    > li {
        position: relative;
        display: flex;
        align-items: start;
        float: left;

        > a {
            position: relative;
            display: block;
            border-bottom: 4px solid transparent;
            padding: 10px 4px;
            line-height: 1;
            color: #fff;
            font-family: $font-family-serif;
            font-size: 18px;
            font-weight: bold;

            &:hover,
            &:focus {
                text-decoration: none;
            }
        }

        &.active > a {
            &,
            &:hover,
            &:focus {
                border-color: $brand-red;
                cursor: default;
            }
        }

        + li {
            margin-left: 20px;
        }

        .unread-count {
            margin-top: 9px;
        }
    }
}

.nav-offcanvas > ul {
    list-style: none;
    margin: 0;
    padding: 0;
    > li {
        display: flex;
        align-items: center;
        position: relative;
        > a {
            position: relative;
            display: block;
            padding: 20px 20px;
            line-height: 1;
            color: #fff;
            font-family: $font-family-serif;
            font-size: 18px;
            font-weight: bold;
            &:hover,
            &:focus {
                background-color: rgba(0, 0, 0, 0.3);
                color: #fff;
                text-decoration: none;
            }
        }
    }
}

.tab-pane {
    background-color: #fff;
    border-right: 1px solid $gray-lighter;
    border-bottom: 1px solid $gray-lighter;
    border-left: 1px solid $gray-lighter;
    padding: 20px;
    margin-bottom: 20px;

    .tab-pane-alert {
        .alert {
            margin: 20px 0 0 0;
        }
    }

    .tab-pane-heading {
        margin: 0 0 15px 0;
    }
}

.nav-tabs {
    > li > a {
        margin-right: 0;
        @include isaac-respond-width(xs) {
            padding-left: 10px;
            padding-right: 10px;
        }
    }
}

// Give the tabs something to sit on
.nav-tabs-minimal {
    display: flex;
    gap: 3rem;
    border-bottom: 1px solid $nav-tabs-border-color;

    &::before,
    &::after {
        display: none;
    }

    > li {
        > a {
            padding: 1.5rem 0;
            line-height: $line-height-base;
            border: 0;
            border-radius: 0;
            border-bottom: 1px solid transparent;

            &:hover {
                background-color: transparent;
                border-color: $nav-tabs-link-hover-border-color;
            }
        }

        &.active > a {
            &,
            &:hover,
            &:focus {
                border: 0;
                border-bottom: 2px solid $brand-red;
                color: $nav-tabs-active-link-hover-color;
                background-color: transparent;
                cursor: default;
            }
        }
    }
}
