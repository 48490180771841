[v-cloak] {
  display: none;
}

.vue-loading {
  display:none;
  &[v-cloak] {
    display:block;
  }
}

.vue-loading-spinner {
  display:inline-block;
  $base-line-height: 24px;
  $spin-duration: 1.6s;

  @keyframes vue-loading-spinner-spin {
    0% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(360deg);
    }
  }

  border-radius: 50%;
  width: $base-line-height;
  height: $base-line-height;
  border: .25rem solid rgba(0,0,0,0.2);
  border-top-color: $gray;
  animation: vue-loading-spinner-spin $spin-duration infinite linear;
  .panel-wizard .panel-body & {
    margin:40px 0;
  }
}

