a.video-preview {
    position: relative;
    display: inline-block;

    &::before,
    &::after {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        content: '';
        display: block;
        transition: opacity 0.15s ease-in-out;
        opacity: 0.66;
    }

    &::before {
        background-color: #000000;
        z-index: 1;
        opacity: 0.25;
    }

    &::after {
        background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 265.84 265.84'%3E%3Cpath fill='%23ffffff' d='M226.92 38.91a133.06 133.06 0 0 0-188 0 128.18 128.18 0 0 0-38.9 94 128.18 128.18 0 0 0 38.9 94 133.06 133.06 0 0 0 188 0 128.14 128.14 0 0 0 38.9-94 128.14 128.14 0 0 0-38.9-94ZM174.43 136l-59.19 45.72-7.26-3.1V87.2l7.18-3.1 59.27 45.72Z'/%3E%3C/svg%3E");
        background-repeat: no-repeat;
        background-size: 15%;
        background-position: center;
        z-index: 2;
    }

    &:hover,
    &:focus {
        &::before {
            opacity: 0.5;
        }

        &::after {
            opacity: 0.9;
        }
    }
}
