.table {
    > thead > tr > th {
        border-bottom-width: 1px;
    }
}

.table-striped {
    > thead > tr > th {
        border-bottom-width: 0;
    }

    > thead,
    > tbody,
    > tfoot {
        > tr {
            > th,
            > td {
                border-top: none;
            }
        }
    }

    > tbody > tr:nth-of-type(even) {
        background-color: #fff;
    }

    &.table-striped-alt {
        > tbody > tr:nth-of-type(odd) {
            background-color: #fff;
        }
        > tbody > tr:nth-of-type(even) {
            background-color: $table-bg-accent;
        }
    }
}

.table-fees {
    > thead,
    > tbody,
    > tfoot {
        > tr {
            > th,
            > td {
                border-top: none;
                padding-left: 0 $grid-gutter-width * 0.5;

                &.table-fees-header {
                    background-color: $table-bg-accent;
                }

                &.table-fees-subheader {
                    background-color: $table-bg-accent;
                }

                &.table-fees-col {
                    border-bottom: 1px solid $table-border-color;
                }

                &.table-fees-region-header {
                    font-weight: bold;
                    border-bottom: 1px solid $table-border-color;
                }
            }
        }
    }
}

.table-boarding-result {
    display: block;
    > thead,
    > tbody,
    > tfoot {
        display: block;

        > tr {
            display: flex;
            justify-content: flex-end;
            padding-top: $table-cell-padding;
            padding-bottom: 25px;
            border-bottom: $table-bottom-border-color dashed 2px;

            &:last-child {
                border-bottom-style: none;
            }

            > td {
                display: block;
                padding-top: 25px;
                padding-bottom: 0;
                &:first-child {
                    min-width: 50%;
                    margin-right: auto;
                    padding-left: 0;
                }
                &:last-child {
                    padding-right: 0;
                }
            }
        }
    }
}

.table-lined {
    // > thead > tr > th {
    //     border-bottom-width: 0;
    // }

    > thead,
    > tbody,
    > tfoot {
        > tr {
            > th,
            > td {
                border-top: 1px solid $gray-lighter;

                &:first-child {
                    padding-left: 0;
                }

                &:last-child {
                    padding-right: 0;
                }
            }
        }

        > tr.additional {
            > th,
            > td {
                border-top: 0;
            }
        }
    }

    > tbody > tr:last-child {
        > th,
        > td {
            border-bottom: 1px solid $gray-lighter;
        }
    }
}

.table-spacious {
    > thead,
    > tbody,
    > tfoot {
        > tr {
            > th,
            > td {
                padding: 1.5rem 0.75rem;
            }
        }

        > tr.additional {
            > th,
            > td {
                padding-top: 0;
            }
        }
    }
}

.table-middle {
    > thead,
    > tbody,
    > tfoot {
        > tr {
            > th,
            > td {
                vertical-align: middle;
            }
        }
    }
}
