body > #content > header {
  color:#fff;
  position:relative;
  background: $gray-darker;
  height: 60px;

  @include isaac-respond-min-width(sm) {
    height: 90px;
  }


  &:before {
    content:'';
    display:block;
    position:absolute;
    left:0;
    top:0;
    height:4px;
    width:100%;
    background: #db0028 url(img/header-border.png) no-repeat top right;
  }

  .logo {
    background: url(img/Atradius_Collections_C_Pos.png) no-repeat;
    position:absolute;
    width:110px;
    height:30px;
    left:10px;
    top:17px;
    background-size: contain;

    @include isaac-respond-min-width(sm) {
      width:175px;
      height:48px;
      left:20px;
      top:(90px - 48px) * 0.5;
    }

  }

  .header-menu {
    display:block;
    position: absolute;
    right:16px;
    bottom:0;
  }

  .header-metadata {
    margin: 18px 0 0 10px;
    @include isaac-respond-min-width(sm) {
      margin: 14px 10px 0 10px;
    }

    .header-metadata-link  > a {
      margin-left:8px;
      text-transform: uppercase;
      font-size:12px;

      @include isaac-respond-min-width(sm) {
        font-size:14px;
        margin-left:15px;
      }
    }

  }


  .header-offcanvas-icon {

  }

  .offcanvas-toggle {
    display: block;
    height: 34px;
    width: 34px;
    padding:6px 8px 0 8px;
    margin-left:6px;
    cursor:pointer;

    // Bars
    .icon-bar {
      display: block;
      width: 18px;
      height: 2px;
      background-color:#fff;
    }

    .icon-bar + .icon-bar {
      margin-top: 4px;
    }
  }

}
