.page-header {
  padding: 19px 0 20px;
  margin: 0;
  border-bottom: none;

  @include isaac-respond-min-width(sm) {
    padding: 24px 0 25px;
  }

  h1, .h1,
  h2, .h2,
  h3, .h3,
  h4, .h4,
  h5, .h5,
  h6, .h6 {
    margin:0;
  }

}

a {
  font-weight: bold;
}

@include isaac-respond-min-width(sm) {
  h1, .h1 {
    font-size:$font-size-sm-h1;
  }
  h2, .h2 {
    font-size:$font-size-sm-h2;
  }
  h3, .h3{
    font-size:$font-size-sm-h3;
  }
  h4, .h4 {
    font-size:$font-size-sm-h4;
  }
  h5, .h5{
    font-size:$font-size-sm-h5;
  }
  h6, .h6{
    font-size:$font-size-sm-h6;
  }
}

h4, .h4,
h5, .h5,
h6, .h6 {
  margin-top: $line-height-computed;
}

.text-serif {
  font-family: $font-family-serif;
}

.list-check {
  @include list-unstyled();
  li {
    position: relative;
    padding:0 0 5px 20px;
    &:before {
      @include icon();
      content: $icons-var-check;
      display:block;
      position:absolute;
      left:0;
      top:4px;
      color: $brand-success;
    }
  }
}

blockquote {
  font-size: $blockquote-font-size;
  border-left: none;
  font-family: $headings-font-family;
  font-size:$font-size-xs-h3;
  font-weight: bold;
  line-height: 1.3;
  padding:0;
  margin-bottom:15px;

  @include isaac-respond-min-width(sm) {
    font-size:$font-size-sm-h3;
  }

  p,
  ul,
  ol {
    &:last-of-type {
      margin-bottom: 5px;
    }
  }

  footer {
    display: block;
    font-size: 80%; // back to default font-size
    font-family: $font-family-base;
    font-weight: normal;

    &:before {
      display:none;
    }
  }
}

.no-margin-top {
  margin-top:0!important;
}

.text-nowrap {
  white-space: nowrap;
}

.text-normal {
  font-weight:normal!important;
}

.text-bold {
  font-weight:bold!important;
}

.underline {
  text-decoration: underline;
}


@include text-emphasis-variant('.text-danger', $brand-danger);
@include text-emphasis-variant('.text-red', $brand-red);

.modal-ajax-text-danger {
  color: #333333
}

small,
.small {
  font-size:13px;
  color: $gray;
}

dd + dt {
  margin-top: 1.5rem;
}
