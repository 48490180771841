$content-grid-spacing: 30px;

.content-grid {
    position: relative;
    left: calc(50% - 50vw);
    width: 100vw;
    padding: $content-grid-spacing 0;
    line-height: 1.75;

    &.content-grid-background-white {
        background-color: #fff;
    }
}

*:not(.content-grid) + .content-grid,
.content-grid + *:not(.content-grid) {
    margin-top: $content-grid-spacing;
}

//
// Header, footer
// --------------------------------------------------
.content-grid-header,
.content-grid-footer {
    padding: $content-grid-spacing 0;
    font-size: 18px;
    font-family: $font-family-serif;

    .content-grid-text-align-center & {
        text-align: center;
    }
}

.content-grid-header-title {
    margin-bottom: 30px;
}

//
// List
// --------------------------------------------------
.content-grid-list {
    display: flex;
    flex-direction: column;
    flex-grow: 1;
    margin: ($grid-gutter-width * -0.25) ($grid-gutter-width * -0.5);

    .content-grid-borderless & {
        margin: 0 ($grid-gutter-width * -1.33);
    }

    .content-grid-2 &,
    .content-grid-4 & {
        @include isaac-respond-min-width(sm) {
            flex-direction: row;
        }
    }

    .content-grid-3 & {
        @include isaac-respond-min-width(md) {
            flex-direction: row;
        }
    }

    .content-grid-4 & {
        @include isaac-respond-min-width(sm) {
            flex-wrap: wrap;
        }
    }
}

//
// Item
// --------------------------------------------------
.content-grid-item {
    display: flex;
    flex-direction: column;
    flex-grow: 1;
    padding: $grid-gutter-width * 0.5;

    .content-grid-borderless & {
        padding: 0 $grid-gutter-width * 1.5;
    }

    .content-grid-2 &,
    .content-grid-4 & {
        @include isaac-respond-min-width(sm) {
            flex-grow: 1;
            width: 50%;
        }
    }

    .content-grid-3 & {
        @include isaac-respond-min-width(md) {
            flex-grow: 1;
            width: 33%;
        }
    }

    .content-grid-4 & {
        @include isaac-respond-min-width(lg) {
            flex-grow: 0;
            width: 25%;
        }
    }

    .content-grid-item-align-center & {
        text-align: center;
    }
}

.content-grid-item-panel {
    margin: 0;
    display: flex;
    flex-direction: column;
    flex-grow: 1;

    .content-grid-background-white & {
        background: $content-bg;
    }

    .content-grid-borderless & {
        background-color: transparent;
        border: 0;
    }
}

.content-grid-item-panel-body {
    .content-grid-borderless & {
        padding: 0;
    }
}

.content-grid-item-body {
}

.content-grid-item-figure {
    width: 60px;
    height: 60px;
    display: inline-flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    margin-bottom: 20px;
    border-radius: 50%;
    background-color: $content-bg;
    border: 1px solid $gray-lighter;
    position: relative;
    overflow: hidden;

    .content-grid-background-white:not(.content-grid-borderless) &,
    .content-grid-borderless:not(.content-grid-background-white) & {
        background: #fff;
    }
}

.content-grid-item-image-mask {
    display: none;

    @supports (mask-image: none) or (-webkit-mask-image: none) {
        display: block;
        background-color: $gray;
        width: 60px;
        height: 60px;
        position: absolute;
        content: '';
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        mask-size: auto 30px;
        -webkit-mask-size: auto 30px;
        mask-repeat: no-repeat;
        -webkit-mask-repeat: no-repeat;
        mask-position: center center;
        -webkit-mask-position: center center;

        .content-grid-item-red & {
            background-color: $brand-red;
        }
        .content-grid-item-black & {
            background-color: $brand-black;
        }
        .content-grid-item-pink & {
            background-color: $brand-pink;
        }
        .content-grid-item-blue & {
            background-color: $brand-blue;
        }
        .content-grid-item-green & {
            background-color: $brand-green;
        }
        .content-grid-item-golden & {
            background-color: $brand-golden;
        }
    }
}

.content-grid-item-image {
    width: auto;
    height: 30px;

    @supports (mask-image: none) or (-webkit-mask-image: none) {
        display: none;
    }
}

.content-grid-item-actions {
    margin-top: 15px;
}
