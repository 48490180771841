//
// Files
.file-list-files {
}

.file-list-file {
    padding: ($grid-gutter-width * 0.5) 0;
    border-bottom: 1px dotted $panel-default-border;

    &:last-child {
        border-bottom: 0;
    }
}

.file-list-file-body {
    display: flex;
    align-items: center;
    gap: 3rem;
}

.file-list-file-name,
.file-list-file-edit,
.file-list-file-remove {
    color: $text-color;
    font-weight: 400;
}

.file-list-file-actions {
    display: flex;
    gap: 0.75rem;
}

.file-list-file-edit,
.file-list-file-remove {
    font-size: 3rem;
    display: flex;
    text-decoration: none !important;
}

.file-list-file-name-wrapper {
    flex-grow: 1;
    text-align: left;
}

.file-list-file-name {
    text-decoration: underline;
}

.file-list-file-amount {
    font-family: $font-family-serif;
    font-weight: 700;
}

.file-list-file-error {
}

.file-list-file-progress {
}

.file-list-file-progress-bar {
}
