.calculation-line {
  @include clearfix();
  margin-bottom:10px;

  .calculation-label {
    float:left;
  }

  .calculation-value {
    float:right;
  }
}

.calculation-divider {
  margin:12px 0 10px 0;
  height:1px;
  background-color: $hr-border;
}