.cookie-consent {

  position: fixed;
  bottom:0;
  z-index: $zindex-cookie-consent;
  background: rgba(0,0,0,0.75);
  width:100%;

  .cookie-consent-content {
    padding: 15px 0 20px;

    .close {
      margin-top:-2px;
      margin-left: 4px;
      color: #fff;
      opacity: 1;
    }

    .cookie-consent-content-body {
      color: #fff;

      a {
        font-weight: normal;
        color: inherit;
        text-decoration: underline;
        &:hover,
        &:focus {
          color: #000;
        }
      }
    }

  }

}