.panel {
  @include box-shadow(none);

  &:not(.panel-not-responsive) {
    //make panels full width on xs
    @include isaac-respond-max-width(xs) {
      margin-left: -$grid-gutter-width * 0.5;
      margin-right: -$grid-gutter-width * 0.5;
      border-left: none;
      border-right: none;
    }
  }


  &.panel-disabled {
    cursor: not-allowed;
    position: relative;
    border: 0;

    &::before {
      content: "";
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      background-color: #fff;
      opacity: 0.5;
      z-index: 1;
    }
  }
}

button.panel-clickable {
  width: 100%;
  text-align: left;
}

.panel-body {
  @include isaac-respond-min-width(sm) {
    padding: $panel-body-sm-padding;
  }

  > h1, > .h1,
  > h2, > .h2,
  > h3, > .h3,
  > h4, > .h4,
  > h5, > .h5,
  > h6, > .h6 {
    &:first-of-type {
      margin-top:0;
    }
  }

  .panel-lg & {
    @include isaac-respond-min-width(sm) {
      padding: $panel-body-lg-padding;
    }
  }
}

.panel-heading {
  @include isaac-respond-min-width(sm) {
    padding: $panel-heading-sm-padding;
  }
}

.panel-footer {
  border-top: none;
  margin-top:-10px; //negate panel-body bottom padding
  @include isaac-respond-min-width(sm) {
    margin-top:-20px; //negate panel-body bottom padding
    padding: $panel-footer-sm-padding;
  }
}

.panel-default {
  &.panel-clickable {
    display: block;
    font-weight: normal;
    cursor: pointer;
    transition: border-color 250ms, box-shadow 250ms;

    &,
    &:focus,
    &:hover {
      color: inherit;
      text-decoration: none;
    }

    &:focus,
    &:hover {
      border-color: darken($panel-default-border,10%);
      box-shadow: 0 0 4px rgba(0,0,0,0.15);
    }
  }
}

.panel-default-primary {
  background: $panel-default-primary-border;
  .panel-body,
  .panel-footer{
    color:#000;
    background: $panel-default-primary-bg;
  }
}

.panel-primary {
  background: $panel-primary-border;
  .panel-body,
  .panel-footer{
    color:#000;
    background: transparent;
  }
}

.panel-light {
  background: $content-bg;
  .panel-body,
  .panel-footer{
    background: $content-bg;
  }
}

.panel-dark {
  background: #333333;

  * {
    color: $content-bg;
  }

  &.panel-clickable {
    &:focus,
    &:hover {
      border-color: #333333;
      box-shadow: 0 0 10px rgba(0,0,0, .66);
    }
  }

  .panel-body,
  .panel-footer{
    color: $content-bg;
    background: #333333;
  }
}

.panel-outline {
  background: transparent;
  border-color: $panel-default-border;
  .panel-footer{
    background: transparent;
  }
}

.panel-outline-light {
  background: transparent;
  border-color: $panel-outline-light-border;
  .panel-footer{
    background: transparent;
  }
}

.panel-transparent {
  background: transparent;
  .panel-footer{
    background: transparent;
  }
}

.panel-img {
  border:0;
  background-size: cover;
  background-position: center center;
}


//overwrite Bootstraps default .panel-group rules
.panel-group-compact {
  .panel {
    + .panel {
      margin-top: -1px;
    }
  }
  .panel-heading {
    + .panel-collapse > .panel-body,
    + .panel-collapse > .list-group {
      border-top: none;
    }
  }
}

.panel-accordion {
  background: #fff;
  border-color: $panel-default-border;

  .panel-heading {
    padding:0; //maximize click area
  }

  .panel-title {
    > a {
      display:block;
      position: relative;
      padding: 20px 40px 20px 20px; //maximize click area
      text-decoration: none;

      //add red bar on top
      &:before {
        display:none;
        background: $brand-red;
        content: '';
        height: 4px;
        left: 0;
        position: absolute;
        top: 0;
        width: 100%;
      }

      //add plus sign
      &:after {
        content: '+';
        font-size:30px;
        display: block;
        position: absolute;
        top:12px;
        right:15px;
        color: inherit;
        font-weight: normal;
        transition: transform .2s ease-out;
      }

      //optional image
      .panel-accordion-img {
        width: 50px;
        height: 50px;
        border-radius: 100%;
        display: inline-block;
        margin: 0 10px 0 0;
        vertical-align: middle;
      }

      //hover state
      &:hover {
        transition: .5s;
      }

    }

    //collapsed state
    > a.collapsed {
      &:hover {
        background-color: $brand-red;
        color: #fff;
        .panel-accordion-img {
          transform: scale(1.1,1.1);
          transition: .2s ease-out;
        }
      }
    }

    //expanded state
    > a:not(.collapsed) {
      &:before {
        display:block;
      }

      &:after {
        transform: rotate(45deg);
      }
    }
  }

}
