.chart-progress-bar {
    display: flex;
    flex-direction: column;
    font-size: $font-size-small;
    gap: 1.5em;
}

.chart-row {
}

.chart-row-header {
    display: flex;
    margin-bottom: 0.75rem;
    gap: .75rem;
    align-items: flex-end;
}

.chart-row-label {
    flex: 1;
}

.chart-row-count {
    margin: 0;
}

.chart-bar {
    .progress {
        box-shadow: none;
        height: 0.75rem;
        margin: 0;
    }
    .progress-bar {
        box-shadow: none;
    }
}
