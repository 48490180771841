.form-group.has-error {
    .help-block {
        color: $gray;
    }

    .control-label {
        color: $gray;
    }

    .form-control {
        border-color: $brand-danger;
        @include box-shadow(none);
    }
}

.form-control {
    font-size: 16px;
    @include box-shadow(none);
    transition: border 400ms;

    &:not(textarea) {
        padding: 0px 10px;
        line-height: 1;
    }

    //compound .form-group (like .form-amount) might contain one field with an error
    //in that case, .form-group doesn't get the .has-error class, but the individual input field only gets this class
    &.has-error {
        border-color: $brand-danger;
    }
}

.control-label {
    font-weight: normal;
    color: $gray;
}

.form-control-static {
    min-height: auto;
    padding: 5px 0 4px 0;
}

.help-block,
.error-block {
    margin: 5px 0 10px;
    font-size: 13px;
    color: $gray;
    line-height: 1.3;
    a {
        font-weight: normal;
        text-decoration: underline;
        color: inherit;
        &:hover,
        &:focus {
            color: $link-hover-color;
        }
    }
}

.error-block {
    display: block;
    color: $brand-danger;
}

input[type='radio'],
input[type='checkbox'] {
    margin-top: 5px;
}

@media (min-width: $screen-sm-min) {
    .form-horizontal .control-label {
        padding-top: 5px;
    }
}

.form-horizontal {
    .radio,
    .checkbox,
    .radio-inline,
    .checkbox-inline {
        padding-top: 5px;
    }
}

.form-group {
    @media (min-width: $screen-sm-min) {
        margin-bottom: $form-group-sm-margin-bottom;
    }
}

//compound currencyamount field (currency, integer and fractional)
.form-amount {
    $currency-width: 74px;
    $currency-margin: 7px;
    $decimalsign-width: 7px;
    $fractional-width: 28px;

    @include clearfix();

    .form-amount-currency,
    .form-amount-integer,
    .form-amount-decimalsign,
    .form-amount-fractional {
        float: left;
    }

    //select.form-amount-currency  is optional
    .form-amount-currency {
        width: $currency-width;
        margin-right: $currency-margin;
    }
    .form-amount-integer {
        width: calc(
            100% - #{$currency-width + $currency-margin + $decimalsign-width +
                $fractional-width}
        );
        text-align: right;
    }

    .form-amount-decimalsign {
        width: $decimalsign-width;
        text-align: center;
        font-size: 28px;
        height: 26px;
        line-height: 1;
        overflow: hidden;
    }
    .form-amount-fractional {
        width: $fractional-width;
        float: left;
        height: 26px;
        font-size: 15px;
        padding-left: 0;
        padding-right: 0;
        text-align: center;
    }
}
.signer {
    padding-top: 15px;
    input[type='checkbox'] {
        display: none;
        & + label {
            display: block;
            position: relative;
            padding-left: 30px;
            cursor: pointer;
            &:before {
                content: '';
                display: block;
                width: 18px;
                height: 18px;
                border: 1px solid #000;
                position: absolute;
                left: 0;
                top: 3px;
                opacity: 0.6;
                transition: all 0.05s, border-color 0.08s;
            }
        }
        &:checked {
            & + label:before {
                width: 10px;
                top: 0px;
                left: 5px;
                border-width: 2px;
                border-radius: 0;
                opacity: 1;
                border-color: $link-color;
                border-top-color: transparent;
                border-left-color: transparent;
                -webkit-transform: rotate(45deg);
                transform: rotate(45deg);
            }
        }
    }
    &.error {
        border-color: $brand-danger;
    }
}
