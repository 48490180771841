.boarding-invalid-document {
  @include clearfix();
  border: 1px solid $brand-danger;
  padding:10px;
  margin:20px 0 0 0;
  background:$content-bg;

  .boarding-invalid-document-name {
    float:left;
    width:calc(100% - 24px);
    padding-right:10px;
    overflow:hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
    direction:rtl;
    text-align:left;
    //make sure brackets ar aligned correct https://stackoverflow.com/questions/5741522/brackets-displays-wrongly-for-right-to-left-display-style
    &:after {
      content: "\200E‎";
    }
  }
  .boarding-invalid-document-action {
    float:right;
    width:24px;
    text-align: right;
    cursor: pointer;
    .icon {

    }
  }
}

.boarding-document {
  @include clearfix();
  border: 1px solid $gray-lighter;
  transition: border 400ms;
  margin:20px 0 0 0;
  position:relative;

  &.has-error {
    border:1px solid $brand-danger;
  }

  .boarding-documents-progress {
    position:absolute;
    left:0;
    top:41px;
    width:100%;
    height: 3px;
    overflow: hidden;
    background-color: transparentize($brand-primary,0.7);

    .boarding-documents-progress-bar {
      float: left;
      width: 0;
      height: 100%;
      background-color: $brand-primary;
      transition: width .6s ease;
    }

  }


  .boarding-document-header {
    background:$content-bg;
    @include clearfix();
    padding:10px;

    .boarding-documents-name {
      float:left;
      width:65%;
      margin-right:10px;

      .boarding-documents-name-title {
        max-width:90%;
        overflow:hidden;
        white-space: nowrap;
        text-overflow: ellipsis;
        direction:rtl;
        text-align:left;
        padding-right:5px;
      }

      .boarding-document-remove {
        font-size:13px;
        font-weight: normal;
        cursor: pointer;
      }
    }

    .boarding-documents-error {
      float:left;
      color:$brand-danger;
    }

    .boarding-documents-amount {
      float:right;
      font-weight: bold;
      margin-right:10px;
    }

    .boarding-documents-actions {
      float:right;
      width:24px;

      .boarding-collapse {
        display: block;
        color: $brand-primary;
        border: 1px solid $brand-primary;
        border-radius: 50%;
        width:24px;
        height:24px;
        text-align: center;
        &:before {
          @include icon();
          content: $icons-var-chevron-up;
          line-height:1;
          font-size:20px;
        }
        &.collapsed {
          &:before {
            content: $icons-var-chevron-down;
          }
        }



      }

    }
  }

 .boarding-details {
    padding:10px;
    margin-top:5px;
    .form-horizontal .control-label {
      text-align: left;
    }
  }
}

.boarding-documents-summary {
  @include clearfix();
  padding: 15px 0;
  border-top: 1px solid $gray-lighter;

  .boarding-documents-summary-inner {
    display: flex;
    align-items: flex-start;
  }

  .boarding-documents-summary-header {
    flex-grow: 1;
    margin-top: 0;
  }
  .boarding-documents-summary-amount {
    font-weight: bold;
    text-align: right;
    margin-right: 100px;
  }
}

.panel-default-primary {
  margin-top: 20px;

  .panel-body {
    display: flex;
    flex-direction: column;

    p {
      margin: 0 0 0 30px;
    }

    .title {
      display: inline-flex;
      flex-wrap: wrap;

      h5 {
        margin: 0 0 0 8px;
        align-self: center;
      }

      .icon {
        font-size: 25px;
        color: $link-color;
      }
    }
  }
}
