body > #content > footer {
  border-top:1px solid $gray-lighter;
  padding:30px 0 20px;

  ul {
    padding-bottom: 15px;
    @include isaac-respond-min-width(sm) {
      padding-bottom: 0;
    }
    > li > a {
      display:block;
      position:relative;
      color: $text-color;
      font-weight: normal;
      padding-bottom:15px;
    }
  }

  .footer-logo {
    padding:0 0 25px 0;
    text-align: center;

    @include isaac-respond-min-width(sm) {
      float: right;
      text-align: left;
    }

    p {
      font-size: $font-size-small;
      color: $gray;
      @include isaac-respond-min-width(sm) {
        float: left;
        margin-right:15px;
      }
    }
    img {
      width:137px;
      height:49px;
    }

  }

  .footer-copyright {
    font-size: $font-size-small;
    color: $gray;
    text-align: center;
    padding-top:15px;
    @include isaac-respond-min-width(sm) {
      text-align: right;
    }
  }
}