.list-group {
  a.list-group-item {
    //reset font weight, inherited from default <a> styling
    font-weight: normal;
  }
  a.list-group-item-footer-link {
    background-color: $list-group-hover-bg;
    &:hover,
    &:focus {
      background-color: darken($list-group-hover-bg,5%);
    }
    &:active {
      background-color: darken($list-group-hover-bg,7%);
    }
  }
}

.list-group-invoices {
  .list-group-item {
    @include isaac-respond-min-width(md) {
      display: flex;
      align-items: center; //make sure buttons and text are vertically aligned
    }
    > * {
      display: block;
      padding: 6px 0;
      width:100%;

      &.list-group-invoices-amount {
        text-align: right;
      }

      &.list-group-invoices-view {

        text-align: center;
      }

      &.list-group-invoices-status {
        text-align: center;
      }

      @include isaac-respond-min-width(lg) {
        padding: 0 6px;
        &:first-child {
          padding-left:0;
        }

        &:last-child {
          padding-right:0;
        }

        &.list-group-invoices-date {
          width: 12%;
        }

        &.list-group-invoices-title {
          width: 36%;
        }

        &.list-group-invoices-amount {
          width: 14%;
        }

        &.list-group-invoices-view {
          width: 18%;
        }

        &.list-group-invoices-status {
          width: 20%;
        }
      }

    }

  }
}